<template>
    <router-link to="/"><div id="zw"></div></router-link>
    <div id="nav">
        <div id="navitems">
            <router-link to="/work">WORK</router-link>
            <router-link to="/prints">PRINTS</router-link>
            <router-link to="/about">ABOUT</router-link>
            
        </div>
    </div>        
</template>

<style scoped>
#nav {
    display: flex;
    justify-content: flex-end;
    float:right;
    margin:50px;
    margin-right:200px;
}
#navitems {
    display: flex;
    justify-content: space-around;
}
#navitems > a {
    text-decoration: none;
    color: #000;
    padding-left: 20px;
    font-family: 'Michroma', sans-serif;
    position: relative;
    text-align:right;
    user-select: none;
}
#navitems > a::after {
    border-radius: 1em;
    border-top: .1em solid #000;
    content: "";
    position: absolute;
    right: 100%;
    bottom: -.2em;
    left: 1.3em;
    transition: right .4s cubic-bezier(0,.5,0,1);
}
#navitems > a:hover::after {
    right: .1em;
}
#zw {
    width:200px;
    margin:50px;
    margin-left:200px;
    background-image: url("../assets/zw.png");
    background-repeat: no-repeat;
    background-size: 200px 74px;
    height:74px;
    display:inline-block;
}
#zw:hover {
    background-image: url("../assets/zw2.png");
    width: 218px;
    background-size: 218px 74px;
}

@media screen and (max-width: 1700px) {
    #zw {
        margin-left:175px;
        width:175px;
        height:65px;
        background-size: 175px 65px;
    }
    #zw:hover {
        background-image: url("../assets/zw2.png");
        width: 218px;
        background-size: 191px 65px;
    }
    #nav {
        margin-right:175px;
        font-size:14px;
    }
    #navitems > a:hover::after {
        right: .05em;
    }
    #navitems > a::after {
        left: 1.5em;
    }
}
@media screen and (max-width: 1480px) {
    #zw {
        margin-left:150px;
        width:175px;
        height:65px;
        background-size: 175px 65px;
    }
    #zw:hover {
        background-image: url("../assets/zw2.png");
        width: 218px;
        background-size: 191px 65px;
    }
    #nav {
        margin-right:150px;
        font-size:12px;
    }
    #navitems > a:hover::after {
        right: .05em;
    }
    #navitems > a::after {
        left: 1.7em;
    }
}
@media screen and (max-width: 1300px) {
    #zw {
        margin-left:140px;
        width:175px;
        height:65px;
        background-size: 175px 65px;
    }
    #zw:hover {
        background-image: url("../assets/zw2.png");
        width: 218px;
        background-size: 191px 65px;
    }
    #nav {
        margin-right:140px;
        font-size:10px;
    }
    #navitems > a:hover::after {
        right: .05em;
    }
    #navitems > a::after {
        left: 2.1em;
    }
}
@media screen and (max-width: 1100px) {
    #zw {
        margin-left:100px;
        width:175px;
        height:65px;
        background-size: 175px 65px;
    }
    #zw:hover {
        background-image: url("../assets/zw2.png");
        width: 218px;
        background-size: 191px 65px;
    }
    #nav {
        margin-right:100px;
        font-size:10px;
    }
    #navitems > a:hover::after {
        right: .05em;
    }
    #navitems > a::after {
        left: 2.1em;
    }
}
@media screen and (max-width: 768px) {
    #zw {
        margin-top:30px;
        margin-left:30px;
        width: 117px;
        height: 43px;
        background-size: 117px 43px;
    }
    #zw:hover {
        background-image: url("../assets/zw2.png");
        width: 129px;
        background-size: 129px 43px;
    }
    #nav {
        font-size: 13px;
        width: 100%;
        margin: 0;
        justify-content: flex-end;
        position:fixed;
        bottom:0px;
        height:50px;
        align-items:center;
        background:#FFF;
        z-index:2;
    }
    #navitems {
        padding-top:0px;
        padding-right:20px;
    }
    #navitems > a {
        padding-right:10px;
        
    }
    #navitems > a:hover::after {
        right: .7em;
    }
    #navitems > a::after {
        left: 1.5em;
    }
    #btt {
        left:0;
        right:auto;
    }
}
@media screen and (max-width: 500px) {
    #navitems {
        padding-right:30px;
    }
    #navitems > a {
        padding-right:0px;
    }
    #nav {
        font-size:11px;
    }
    #navitems > a:hover::after {
        right: 0em;
    }
    #navitems > a::after {
        left: 1.8em;
    }
}
</style>
<script>
    export default {
    name: "Nav",
    mounted() {
        const script = function (p5) {
            let density = 25;
            var particles = [];
            var noiseScale = 0.012;
            var zoff = 0;
            var rocks = [];
            //var angle;
            var isAttracted;
            var over;
            //var name = "zachariah watson is a web dev from kansas city,mo";
            var name = "zachariahwatson";
            var nameCount = 0;
            var border = 75;
            var borderW = 125;
            var borderTop = 125;
            name = name.split("").map(function (c) {
                return c[Math.round(Math.random()) ? "toUpperCase" : "toLowerCase"]();
            }).join("");
            var fonts = ["Special Elite", "Shrikhand", "Abril Fatface", "Pirata One"];
            p5.setup = () => {
                //noiseDetail(8);
                let canvas = p5.createCanvas(document.body.clientWidth, window.innerHeight);
                canvas.position(0, 0);
                canvas.style("z-index", "-2");
                canvas.style("position", "fixed");
                if (p5.width <= 1700 && p5.width > 1480) {
                    borderW = 110;
                    density = 25;
                    borderTop = 110;
                }
                else if (p5.width <= 1480 && p5.width > 1300) {
                    borderW = 110;
                    density = 20;
                    borderTop = 110;
                }
                else if (p5.width <= 1300 && p5.width > 1100) {
                    borderW = 100;
                    density = 20;
                    borderTop = 100;
                }
                else if (p5.width <= 1100 && p5.width > 768) {
                    borderW = 70;
                    density = 15;
                    borderTop = 100;
                }
                else if (p5.width <= 768) {
                    borderW = 30;
                    density = 15;
                    borderTop = 70;
                }
                else {
                    border = 75;
                    borderW = 125;
                    borderTop = 125;
                    density = 25;
                }
                for (let y = borderTop; y < p5.height - border; y += (p5.height - border) / density) {
                    for (let x = (p5.width - borderW) / density; x < p5.width - borderW; x += (p5.width - borderW) / density) {
                        particles.push(new Particle(x, y, p5.randomGaussian(3, 1))); //2.5,.5
                    }
                }
            };
            p5.draw = () => {
                p5.background(0, 0, 100, 0.25);
                //clear();
                particles.forEach(particle => {
                    particle.drawParticle();
                });
                over = false;
                rocks.forEach(rock => {
                    rock.over();
                    rock.update();
                    rock.drawRock();
                    if (rock.isOver) {
                        over = true;
                    }
                });
                if (over && document.getElementsByClassName("content").length == 0) {
                    //p5.cursor('grab');
                    document.querySelector("*").style.cursor = "grab";
                    if (p5.mouseIsPressed) {
                        document.querySelector("*").style.cursor = "grabbing";
                        //p5.cursor('grabbing');
                    }
                }
                else {
                    document.querySelector("*").style.cursor = "default";
                    //p5.cursor('default');
                }
                zoff += 0.2;
                /*p5.fill(0,0,100);
                p5.textAlign(p5.CENTER,p5.CENTER);
                p5.textSize(10);
                p5.textFont('Michroma');
                p5.text('click  to  add // drag  to  move',p5.width/2,p5.height-borderTop/3);*/
                //p5.noLoop();
            };
            class Particle {
                constructor(x, y, r) {
                    this.x = x;
                    this.y = y;
                    this.r = r;
                    this.x0 = x;
                    this.y0 = y;
                }
                drawParticle() {
                    isAttracted = false;
                    rocks.forEach(rock => {
                        if (p5.dist(this.x, this.y, rock.x, rock.y) < 75) {
                            angle = p5.dist(this.x, this.y, rock.x, rock.y) + p5.map(p5.noise(this.x * noiseScale, this.y * noiseScale, zoff * noiseScale), 0, 1, 0, 360) / 7;
                            isAttracted = true;
                        }
                    });
                    if (!(isAttracted)) {
                        if (p5.dist(this.x, this.y, p5.mouseX, p5.mouseY) < 100) {
                            angle = p5.dist(this.x, this.y, p5.mouseX, p5.mouseY) + p5.map(p5.noise(this.x * noiseScale, this.y * noiseScale, zoff * noiseScale), 0, 1, 0, 360) / 7;
                        }
                        else {
                            angle = p5.map(p5.noise(this.x * noiseScale, this.y * noiseScale, zoff * noiseScale), 0, 1, 0, 360);
                        }
                    }
                    if (this.x >= p5.width - borderW) {
                        //this.x = this.x0;
                        this.x = borderW + 1;
                    }
                    else if (this.x <= borderW) {
                        //this.x = this.x0;
                        this.x = p5.width - borderW - 1;
                    }
                    if (this.y >= p5.height - border) {
                        //this.y = this.y0;
                        this.y = borderTop + 1;
                    }
                    else if (this.y <= borderTop) {
                        //this.y = this.y0;
                        this.y = p5.height - border - 1;
                    }
                    if (p5.mouseIsPressed && p5.dist(p5.mouseX, p5.mouseY, this.x, this.y) < 100) {
                        var angle = Math.atan2(this.y - p5.mouseY, this.x - p5.mouseX) + p5.map(p5.noise(this.x * noiseScale, this.y * noiseScale, zoff * noiseScale), 0, 1, -360, 360) / 100;
                        this.x += Math.cos(angle) / this.r / p5.dist(p5.mouseX, p5.mouseY, this.x, this.y) * 250;
                        this.y += Math.sin(angle) / this.r / p5.dist(p5.mouseX, p5.mouseY, this.x, this.y) * 250;
                    }
                    else {
                        this.x += Math.cos(angle) / this.r * 2;
                        this.y += Math.sin(angle) / this.r * 2;
                    }
                    p5.colorMode(p5.HSB, 360, 100, 100);
                    p5.push();
                    p5.noStroke();
                    p5.fill(0, 0, p5.map(angle, 0, 360, 50, 0), 1);
                    p5.translate(this.x, this.y);
                    p5.rotate(angle);
                    //this.particleShape();
                    p5.rectMode(p5.CENTER);
                    p5.rect(0, 0, this.r);
                    p5.pop();
                }
                particleShape() {
                    p5.beginShape();
                    p5.curveVertex(0 - this.r * 1.5, 0);
                    p5.curveVertex(0, 0 - this.r / 2);
                    p5.curveVertex(0 + this.r / 2, 0);
                    p5.curveVertex(0, 0 + this.r / 2);
                    p5.curveVertex(0 - this.r * 1.5, 0);
                    p5.curveVertex(0, 0 - this.r / 2);
                    p5.curveVertex(0 + this.r / 2, 0);
                    p5.endShape();
                }
            }
            class Rock {
                constructor(x, y) {
                    this.x = p5.mouseX;
                    this.y = p5.mouseY;
                    this.ang = p5.map(p5.noise(this.x, this.y), 0, 1, -45, 45);
                    this.l = p5.random(20, 40);
                    this.w = p5.random(20, 40);
                    this.textSize = p5.random(35, 50);
                    this.dragging = false;
                    this.isOver = false;
                }
                over() {
                    if (p5.mouseX > this.x - this.w / 2 && p5.mouseX < this.x - this.w / 2 + this.w && p5.mouseY > this.y - this.l / 2 && p5.mouseY < this.y - this.l / 2 + this.l) {
                        this.isOver = true;
                    }
                    else {
                        this.isOver = false;
                    }
                }
                update() {
                    if (this.dragging) {
                        this.x = p5.mouseX + this.offsetX;
                        this.y = p5.mouseY + this.offsetY;
                    }
                }
                drawRock() {
                    p5.push();
                    p5.noStroke();
                    p5.translate(this.x, this.y);
                    p5.rotate(p5.radians(this.ang));
                    p5.fill(0);
                    p5.textAlign(p5.CENTER, p5.CENTER);
                    p5.textSize(this.textSize);
                    p5.textFont(fonts[p5.floor(p5.map(p5.noise(this.x, this.y), 0, 1, 0, 3))]);
                    p5.text(name.charAt(rocks.indexOf(this)), 0, 0);
                    p5.pop();
                }
                pressed() {
                    if (p5.mouseX > this.x - this.w / 2 && p5.mouseX < this.x - this.w / 2 + this.w && p5.mouseY > this.y - this.l / 2 && p5.mouseY < this.y - this.l / 2 + this.l) {
                        this.dragging = true;
                        this.offsetX = this.x - p5.mouseX;
                        this.offsetY = this.y - p5.mouseY;
                    }
                }
                released() {
                    this.dragging = false;
                }
            }
            p5.mouseClicked = () => {
                var rx = borderW;
                var ry = borderTop;
                var rw = p5.width - borderW * 2;
                var rh = p5.height - border - borderTop;
                var overlap = false;
                if (p5.mouseX >= rx && // right of the left edge AND
                    p5.mouseX <= rx + rw && // left of the right edge AND
                    p5.mouseY >= ry && // below the top AND
                    p5.mouseY <= ry + rh) { // above the bottom
                    overlap = false;
                }
                else {
                    overlap = true;
                }
                rocks.forEach(rock => {
                    rock.over();
                    if (rock.isOver == true) {
                        overlap = true;
                    }
                });
                if (rocks.length < name.length && overlap == false && document.getElementsByClassName("content").length == 0) {
                    rocks.push(new Rock(p5.mouseX, p5.mouseY));
                } /*else if (nameCount == 0 && overlap == false) {
                    rocks = [];
                    nameCount = 1;
                    name = "he is currently a student at northwest mo state university";
                }*/
            };
            p5.doubleClicked = () => {
                rocks = [];
            };
            p5.mousePressed = () => {
                rocks.forEach(rock => {
                    rock.pressed();
                });
            };
            p5.mouseReleased = () => {
                rocks.forEach(rock => {
                    rock.released();
                });
            };
            p5.touchStarted = () => {
                var rx = borderW;
                var ry = borderTop;
                var rw = p5.width - borderW * 2;
                var rh = p5.height - border - borderTop;
                var overlap = false;
                if (p5.mouseX >= rx && // right of the left edge AND
                    p5.mouseX <= rx + rw && // left of the right edge AND
                    p5.mouseY >= ry && // below the top AND
                    p5.mouseY <= ry + rh) { // above the bottom
                    overlap = false;
                }
                else {
                    overlap = true;
                }
                rocks.forEach(rock => {
                    rock.over();
                    if (rock.isOver == true) {
                        overlap = true;
                    }
                });
                if (rocks.length < name.length && overlap == false) {
                    rocks.push(new Rock(p5.mouseX, p5.mouseY));
                } /*else if (nameCount == 0 && overlap == false) {
                    rocks = [];
                    nameCount = 1;
                    name = "heiscurrentlyastudentatnorthwestmostateuniversity";
                }*/
            };
            p5.windowResized = () => {
                p5.resizeCanvas(document.body.clientWidth, window.innerHeight);
                particles = [];
                rocks = [];
                if (p5.width <= 1700 && p5.width > 1480) {
                    borderW = 110;
                    density = 25;
                    borderTop = 110;
                }
                else if (p5.width <= 1480 && p5.width > 1300) {
                    borderW = 110;
                    density = 20;
                    borderTop = 110;
                }
                else if (p5.width <= 1300 && p5.width > 1100) {
                    borderW = 100;
                    density = 20;
                    borderTop = 100;
                }
                else if (p5.width <= 1100 && p5.width > 768) {
                    borderW = 70;
                    density = 15;
                    borderTop = 100;
                }
                else if (p5.width <= 768) {
                    borderW = 30;
                    density = 15;
                    borderTop = 70;
                }
                else {
                    border = 75;
                    borderW = 125;
                    borderTop = 125;
                    density = 25;
                }
                for (let y = borderTop; y < p5.height - border; y += (p5.height - border) / density) {
                    for (let x = (p5.width - borderW) / density; x < p5.width - borderW; x += (p5.width - borderW) / density) {
                        particles.push(new Particle(x, y, p5.randomGaussian(3, 1))); //2.5,.5
                    }
                }
            };
            //             /*let mouseRadius = 75;
            //             let yoff = 0;*/
            //             let particles = [];
            //             p5.setup = () => {
            //                 p5.colorMode(p5.HSB);
            //                 let canvas = p5.createCanvas(document.body.clientWidth,p5.windowHeight);
            //                 canvas.position(0,0);
            //                 canvas.style('z-index', '-2');
            //                 canvas.style('position', 'fixed');
            //                 p5.frameRate(60);
            //                 for (let i = 0; i < 2500; i++) {
            //                     particles.push(new Particle());
            //                 }
            //             }
            //             p5.windowResized = () => {
            //                 p5.resizeCanvas(document.body.clientWidth,p5.windowHeight);
            //                 for (let i = 0; i < particles.length; i++) {
            //                     particles[i].repopulate();
            //                     particles[i].moveParticle(p5.mouseX, p5.mouseY/*,0,p5.width,0,p5.height,1*/);
            //                 }
            //             }
            //             p5.draw = () => {
            //                 /*p5.clear();
            //                 p5.background(0);
            //                 for (let y = p5.height/(p5.height/25)*-1; y < p5.height; y+=p5.height/(p5.height/25)) {
            //                     p5.noFill();
            //                     p5.stroke(90);
            //                     p5.strokeWeight(1);
            //                     for (let x = 0; x <= p5.width+p5.width/(p5.width/25); x+=p5.width/(p5.width/25)) {
            //                         if (p5.dist(x,y,p5.mouseX,p5.mouseY) < mouseRadius) {
            //                             p5.ellipse(x,y + 25*p5.sin(x + yoff )-mouseRadius+p5.dist(x,y,p5.mouseX,p5.mouseY),3);
            //                         } else if (p5.dist(x,y,p5.mouseX,p5.mouseY) < mouseRadius*2) {
            //                             p5.ellipse(x,y + 25*p5.sin(x + yoff )+mouseRadius*2-p5.dist(x,y,p5.mouseX,p5.mouseY),3);
            //                         } else if (p5.dist(x,y,p5.mouseX,p5.mouseY) < mouseRadius*3) {
            //                             p5.ellipse(x,y + 25*p5.sin(x + yoff )-mouseRadius*3+p5.dist(x,y,p5.mouseX,p5.mouseY),3);
            //                         } else {
            //                             p5.ellipse(x,y + 25*p5.sin(x + yoff),1);
            //                         }
            //                     }
            //                 }
            //                 yoff += .01;*/
            //                 p5.background(360,0,0,100);
            //                 for (let i = 0; i < particles.length; i++) {
            //                     particles[i].createParticle();
            //                     particles[i].moveParticle(p5.mouseX, p5.mouseY/*,0,p5.width,0,p5.height,1*/);
            //                 }
            //             }
            //             class Particle {
            //                 constructor() {
            //                     this.x = p5.random(0,p5.width);
            //                     this.startingX = this.x;
            //                     this.y = p5.random(0,p5.height);
            //                     this.startingY = this.y;
            //                     this.r = p5.random(2);
            //                     this.yoff = 0;
            //                     this.p = p5.random(5);
            //                     this.random = p5.random(0,50);
            //                     this.random2 = p5.int(p5.random(3));
            //                 }
            //                 createParticle() {
            //                     p5.noStroke();
            //                     p5.circle(this.x,this.y,this.r);
            //                 }
            //                 moveParticle(deltaX,deltaY/*,deltaXLower,deltaXUpper,deltaYLower,deltaYUpper,speed*/) {
            //                     //this.y+=sin(this.x + this.yoff);
            //                     if (this.random2 == 1) {
            //                         p5.fill(210,this.random,100,p5.map(p5.sin(this.startingX + this.yoff),-1,1,0,1));
            //                     } else {
            //                         p5.fill(22,this.random,100,p5.map(p5.sin(this.startingY + this.yoff),-1,1,0,1));
            //                     }
            //                     this.yoff += .04;
            //                     //parallax
            //                     /*if (this.x > p5.width) { 
            //                         this.x -= p5.width;
            //                     } else if (this.x < 0) {
            //                         this.x = p5.width;
            //                     }
            //                     if (this.y > p5.height) {
            //                         this.y -= p5.height;
            //                     } else if (this.y < 0) {
            //                         this.y = p5.height;
            //                     }*/
            //                     this.x = p5.map(deltaX,0,p5.width,this.startingX+this.p*5,this.startingX-this.p*5);
            //                     this.y = p5.map(deltaY,0,p5.height,this.startingY+this.p*5,this.startingY-this.p*5);
            //                 }
            //                 repopulate() {
            //                     this.x = p5.random(0,p5.width);
            //                     this.y = p5.random(0,p5.height);
            //                     this.startingX = this.x;
            //                     this.startingY = this.y;
            //                 }
            // }
            //             }
        };
        const P5 = require("p5");
        new P5(script);
    }
}
</script>