import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    component: () =>
    import("../views/Home.vue"),
  },
  {
    path: "/about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/About.vue"),
  },
  {
    path: "/prints",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import("../views/Prints.vue"),
  },
  {
    path: "/work",
    component: () =>
      import("../views/Work.vue"),
  },
  {
    path: "/work/arcadia",
    component: () =>
      import("../views/Arcadia.vue"),
  },
  {
    path: "/work/surge",
    component: () =>
      import("../views/Surge.vue"),
  },
  {
    path: "/work/genuary2024",
    component: () =>
      import("../views/Genuary2024.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { el: to.hash };
    }
  } 
});

export default router;
