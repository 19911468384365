<template>
  <Nav/>
  <router-view></router-view>
</template>

<style>
html {
    height:100%;
    background-color:white;
    overflow-y: scroll;
}
body {
    padding:0px;
    margin:0px;
}
h2 {
    color:#000;
    font-family: 'Michroma', sans-serif;
    font-size:35px;
    margin-top:0px;
    margin-bottom:0px;
    text-align: center;
    /*margin-left:200px;
    margin-right:200px;*/
    background:linear-gradient(to top, white 50%,transparent);
}
a {
    color:#000;
}
h1 {
    color:#000;
    font-family: 'Roboto', sans-serif;
    font-size:20px;
    font-weight: 700;
    font-style:italic;
    margin-top:0px;
    margin-bottom:0px;
    margin-left:400px;
    margin-right:400px;
}
.center {
    text-align: center;
}
.lv2 {
    font-weight: 300;
    font-size:18px;
}
.single {
    width: 50% !important;
}
p {
    color:#000;
    font-family: 'Roboto', sans-serif;
    margin-top:0px;
    margin-bottom:0px;
    margin-left: 400px;
    margin-right: 400px;
}
.content {
    /*background:linear-gradient(to top, black ,transparent);*/
    background: #FFF;
    padding-top:50px;
}
.container {
  display: flex;
  flex-flow: column wrap;
  align-content: space-between;
  /* Your container needs a fixed height, and it 
   * needs to be taller than your tallest column. */
  height: 1000px; 
  margin-left:200px;
  margin-right:200px;
}
#yorkiemockup {
    height: 1350px; 
}
#sigtau {
    height: 3000px;
}
#knacktivesketches {
    height: 2050px;
}
.item {
  width: 32%;
  margin-bottom: 2%; /* Optional */
  position:relative;
}
.imageoverlay {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: rgba(255, 255, 255, 0.5);
    color: #000;
    font-family: 'Michroma', sans-serif;
    display:flex;
    align-items:center;
    justify-content:center;
    opacity:0;
    transition: opacity 0.25s;
    border-radius: 8px;
    flex-direction: column;
}
.imageoverlay--blur {
    backdrop-filter: blur(5px);
}
.imageoverlay > div {
    transform: translateY(20px);
    transition: transform 0.25s;
}
.imageoverlay:hover {
    opacity: 1;
}
.imageoverlay:hover > div {
    transform: translateY(0);
}
.overlaytitle {
    font-size:20px;
    text-align:center;
    padding:25px;
    padding-top: 0px;
}
/* Re-order items into 3 rows */
/* .item:nth-child(3n+1) { order: 1; }
.item:nth-child(3n+2) { order: 2; }
.item:nth-child(3n)   { order: 3; } */
/*switch back when you have more stuff to add*/
.item:nth-child(3n+1) { order: 1; }
.item:nth-child(3n+2) { order: 2; }
.item:nth-child(3n)   { order: 3; }

/* Force new columns */
.container::before,
.container::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}
.imagecontainer {
    display: flex;
    justify-content: center;
    margin-left:400px;
    margin-right:400px;
}
#about {
    margin-left:400px;
    margin-right:400px;
}
#about > div {
    text-align: left;
}
.imagecontainer > div {
    width:1000px;
    max-height:100%;
    margin:15px;
    text-align: center;
}
.imagecontainer > div > img {
    width:100%;
    max-height:100%;
    border-radius:8px;
}
.imgcaption {
    margin-left:0;
    margin-right:0;
    text-align: center;
    color:#818181;
}
.wrap {
    flex-flow:wrap;
}
#mobileyorkies > div {
    width:250px;
    max-height:100%;
}
iframe {
    border:0;
    border-radius:6px;
    display:block;
}
@media screen and (max-width: 1700px) {
    .container {
        height: 700px; 
        margin-left:175px;
        margin-right:175px;
    }
    h2 {
        font-size:30px;
    }
    .overlaytitle {
        font-size:15px;
    }
    .imagecontainer {
        margin-left:350px;
        margin-right:350px;
    }
    .imagecontainer > div {
        margin:12px;
    }
    #about {
        margin-left:250px;
        margin-right:250px;
    }
    p {
        margin-left: 350px;
        margin-right: 350px;
    }
    h1 {
        margin-left:350px;
        margin-right:350px;
    }
    #yorkiemockup {
        height: 1200px; 
    }
    #knacktivesketches {
        height: 1850px;
    }
}
@media screen and (max-width: 1480px) {
    .container {
        height: 600px; 
        margin-left:150px;
        margin-right:150px;
    }
    h2 {
        font-size:27px;
    }
    .overlaytitle {
        font-size:13px;
    }
    .imagecontainer {
        margin-left:300px;
        margin-right:300px;
    }
    .imagecontainer > div {
        margin:10px;
    }
    #about {
        margin-left:180px;
        margin-right:180px;
    }
    p {
        margin-left: 300px;
        margin-right: 300px;
    }
    h1 {
        margin-left:300px;
        margin-right:300px;
    }
    #yorkiemockup {
        height: 1100px; 
    }
    #knacktivesketches {
        height: 1650px;
    }
}
@media screen and (max-width: 1300px) {
    .container {
        height: 2000px; 
        margin-left:140px;
        margin-right:140px;
    }
    h2 {
        font-size:23px;
    }
    .overlaytitle {
        font-size:12px;
    }
    .imagecontainer {
        margin-left:200px;
        margin-right:200px;
    }
    .imagecontainer > div {
        margin:8px;
    }
    #about {
        margin-left:150px;
        margin-right:150px;
    }
    p {
        margin-left: 200px;
        margin-right: 200px;
    }
    h1 {
        margin-left:200px;
        margin-right:200px;
    }
    #yorkiemockup {
        height:2200px;
    }
    #sigtau {
        height:3800px;
    }
    #knacktivesketches {
        height: 3500px;
    }
    .knacktiveframe {
        height: 600px;
    }
    /* Re-order items into 2 rows */
    .item:nth-child(2n+1) { order: 1; }
    .item:nth-child(2n)   { order: 2; }
    .item {
        width: 49%;
    }
}
@media screen and (max-width: 1100px) {
    .container {
        height: 1700px; 
        margin-left:100px;
        margin-right:100px;
    }
    .imagecontainer {
        margin-left:100px;
        margin-right:100px;
    }
    .imagecontainer > div {
        margin:8px;
    }
    #about {
        margin-left:110px;
        margin-right:110px;
    }
    p {
        margin-left: 100px;
        margin-right: 100px;
    }
    h1 {
        margin-left:100px;
        margin-right:100px;
    }
    h2 {
        margin-left:100px;
        margin-right:100px;
    }
    #yorkiemockup {
        height:1900px;
    }
    #sigtau {
        height:3400px;
    }
    #knacktivesketches {
        height: 3100px;
    }
    .knacktiveframe {
        height: 500px;
    }
}
@media screen and (max-width: 768px) {
    .container {
        height: 1100px; 
        margin-left:75px;
        margin-right:75px;
        padding-bottom:50px;
    }
    .imagecontainer {
        margin-left:30px;
        margin-right:30px;
        flex-flow:wrap;
    }
    .imagecontainer > div {
        margin:8px;
    }
    #about {
        padding-left:30px;
        padding-right:30px;
        margin-left:0;
        margin-right:0;
        flex-wrap:wrap;
        background:#FFF;
        padding-bottom:50px;
    }
    p {
        margin-left: 30px;
        margin-right: 30px;
    }
    h1 {
        margin-left:30px;
        margin-right:30px;
    }
    #yorkiemockup {
        height:1500px;
        margin-left:30px;
        margin-right:30px;
    }
    #sigtau {
        height:2700px;
        margin-left:30px;
        margin-right:30px;
    }
    #issiframe {
        height: 500px;
    }
    .knacktiveframe {
        height: 350px;
    }
    #knacktivesketches {
        height: 2100px;
    }
    .knacktive {
        margin: 8px;
    }
    .single {
        width:100% !important;
    }
}
@media screen and (max-width: 500px) {
    .container {
        height: 100%; 
        margin-left:30px;
        margin-right:30px;
        flex-flow:column;
    }
    .item {
        margin-bottom:10px;
        width:100%;
        position:relative;
        order:unset;
    }
    h2 {
        font-size:20px;
        margin-left:30px;
        margin-right:30px;
    }
    .overlaytitle {
        font-size:12px;
    }
    .imagecontainer {
        margin-left:30px;
        margin-right:30px;
    }
    .imagecontainer > div {
        margin:8px;
    }
    #about {
        padding-left:30px;
        padding-right:30px;
        margin-left:0;
        margin-right:0;
        flex-wrap:wrap;
        background:#FFF;
        padding-bottom:50px;
    }
    #issiframe {
        height: 230px;
    }
    #yorkieiframe {
        height: 500px;
    }
    .knacktiveframe {
        height: 200px;
    }
    #yorkiemockup {
        height:100%;
    }
    #sigtau {
        height:100%;
    }
    #knacktivesketches {
        height:100%;
    }
}

</style>
<script>
import Nav from './components/Nav.vue';
export default {
    components: {
        Nav
    },
}
</script>



